import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import typographySizes from "src/assets/styles/typographySizes.js"
import Divider from "src/components/global/Divider.js"

const StyledDivider = styled(Divider)`
  margin-top: 10px;
  margin-bottom: 8px;
`

const ChangeWrapper = styled.div`
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`

const NoChangeWrapper = styled.div`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
  }
`

const BottomMobile = styled.span`
  display: block;
  font-size: ${typographySizes.s}px;
  margin-top: 10px;
  line-height: 1.2;
`

const Arrow = styled.div`
  position: absolute;
  transform: translate(-200%, -40%);
  left: 50%;
  top: 50%;
  z-index: 0;
  display: flex;
  align-items: center;
  transition: all 0.5s;
`

const ArrowHead = styled.div`
  border: solid ${colors.mainColor};
  border-width: 0 25px 25px 0;
  display: inline-block;
  padding: 25px;
  transform: scale(0) rotate(-45deg);
  transition: all 0.5s 0.2s;
`

const ArrowBody = styled.div`
  height: 25px;
  width: 0px;
  background-color: ${colors.mainColor};
  transform: translate(60px, 0);
  transition: all 0.3s;
`

const ArrowWraper = styled.div`
  position: absolute;
  display: flex;
  /* width: 100%; */
  transition: all 0.5s;
  flex-direction: row;
  z-index: -1;
  top: 15px;
  &.show ${Arrow} {
    transform: translate(calc(-50% - 55px), -40%);
    transition: all 1s cubic-bezier(0.17, 0.67, 0.03, 1.33);
    /* opacity: 1; */
  }
  &.show ${ArrowHead} {
    transform: scale(1) rotate(-45deg);
    transition: all 1s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
  &.show ${ArrowBody} {
    width: 155px;
    transition: all 1s 0.2s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
`

const HoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 13px 0;

  span {
    transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .bottom {
    display: inline-block;
  }
`

const ChangeText = props => {
  const [height, setHeight] = useState()
  const [heightTop, setHeightTop] = useState()

  const [width, setWidth] = useState()

  const container = useRef()

  useEffect(() => {
    setHeight(container.current.querySelector(".bottom").clientHeight)
    setHeightTop(container.current.querySelector(".top").clientHeight)

    setWidth(container.current.querySelector(".bottom").clientWidth)

    window.addEventListener("resize", () => {
      setHeight(container.current.querySelector(".bottom").clientHeight)
      setHeightTop(container.current.querySelector(".top").clientHeight)

      setWidth(container.current.querySelector(".bottom").clientWidth)
    })
  }, [])

  return (
    <>
      <ChangeWrapper>
        <ArrowWraper
          className={props.hover ? "show" : null}
          style={{ width: width + 55, height: height }}
        >
          <Arrow>
            <ArrowBody className={"ArrowBody"} />
            <ArrowHead className={"ArrowHead"} />
          </Arrow>
        </ArrowWraper>
        <HoverWrapper
          ref={container}
          style={{ height: props.hover ? heightTop : heightTop }}
        >
          <span
            className={"top"}
            style={
              props.hover
                ? {
                    transform: `translateY(${-heightTop}px)`,
                    opacity: 0,
                  }
                : null
            }
          >
            {props.children}
          </span>

          <span
            className={"bottom"}
            style={
              props.hover
                ? { transform: `translateY(${-heightTop}px)`, opacity: 1 }
                : { opacity: 0 }
            }
          >
            {props.hoverText}
          </span>
        </HoverWrapper>
      </ChangeWrapper>
      <NoChangeWrapper>
        <div style={props.oneText ? { display: `none` } : null}>
          {props.children}
        </div>
        {/* <StyledDivider /> */}
        <BottomMobile className={props.oneText ? "oneText" : null}>
          {props.News ? ` ${props.dateMobile} ` : `${props.hoverText}`}
        </BottomMobile>
      </NoChangeWrapper>
    </>
  )
}
export default ChangeText

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import ChangeText from "src/components/global/typography/changeText"
import ChangeTextNewsDate from "src/components/global/typography/changeTextNewsDate.js"
import typographySizes from "src/assets/styles/typographySizes.js"
import mediaQuery from "src/assets/styles/mediaQuery"

const StyledLink = styled(Link)`
  font-size: ${typographySizes.xl}px;
  text-decoration: none;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  line-height: 1;
  padding: 10px 0;
  overflow: hidden;
  text-transform: lowercase;
  width: 100%;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
    padding: 0px 0;
  }
  /* background-color: red; */
`

const Date = styled.p`
  font-size: ${typographySizes.l}px;
  text-align: left;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}px;
    text-align: right;
  }
`

const Wrapper = styled.button`
  position: relative;
  z-index: 0;
  margin-bottom: 15px;
  transition: all 0.5s;
  width: 100%;
  :last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
`

const TextWrap = styled.div`
  position: relative;
`

class NewsLink extends React.Component {
  state = { hoverON: false }

  showArrow = () => {
    this.setState({
      hoverON: true,
    })
  }

  hideArrow = () => {
    this.setState({
      hoverON: false,
    })
  }

  render() {
    return (
      <Wrapper
        className={this.state.hoverON ? "show" : ""}
        onMouseEnter={this.showArrow}
        onMouseLeave={this.hideArrow}
      >
        <TextWrap>
          <StyledLink to={`${this.props.link}`}>
            <ChangeText
              hoverText={
                this.props.category === "opinia"
                  ? "opinion"
                  : this.props.category
              }
              hover={this.state.hoverON}
              categoryMobile={
                this.props.category === "opinia"
                  ? "opinion"
                  : this.props.category
              }
              dateMobile={this.props.date}
              News
            >
              {this.props.children}
            </ChangeText>
          </StyledLink>
        </TextWrap>
        <Date>
          <ChangeTextNewsDate
            hoverText={this.props.author}
            hover={this.state.hoverON}
          >
            {this.props.date}
          </ChangeTextNewsDate>
        </Date>
      </Wrapper>
    )
  }
}

export default NewsLink

import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"

import mediaQuery from "src/assets/styles/mediaQuery"

const ChangeWrapper = styled.div`
  overflow-y: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`

// const NoChangeWrapper = styled.div`
//   display: none;
//   @media (max-width: ${mediaQuery.tablet}) {
//     display: flex;
//     justify-content: flex-end;
//   }
// `

// const BottomMobile = styled.span`
//   display: block;
//   margin-left: 5px;
// `

const HoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  span {
    transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .bottom {
    display: inline-block;
  }
`

const ChangeText = props => {
  // const [height, setHeight] = useState()
  const [heightTop, setHeightTop] = useState()

  // const [width, setWidth] = useState()

  const container = useRef()

  useEffect(() => {
    // setHeight(container.current.querySelector(".bottom").clientHeight)
    setHeightTop(container.current.querySelector(".top").clientHeight)

    // setWidth(container.current.querySelector(".bottom").clientWidth)

    window.addEventListener("resize", () => {
      // setHeight(container.current.querySelector(".bottom").clientHeight)
      setHeightTop(container.current.querySelector(".top").clientHeight)

      // setWidth(container.current.querySelector(".bottom").clientWidth)
    })
  }, [])

  return (
    <>
      <ChangeWrapper>
        <HoverWrapper
          ref={container}
          style={{ height: props.hover ? heightTop : heightTop }}
        >
          <span
            className={"top"}
            style={
              props.hover
                ? {
                    transform: `translateY(${-heightTop}px)`,
                    opacity: 0,
                  }
                : null
            }
          >
            {props.children}
          </span>
          <span
            className={"bottom"}
            style={
              props.hover
                ? { transform: `translateY(${-heightTop}px)`, opacity: 1 }
                : { opacity: 0 }
            }
          >
            {props.hoverText}
          </span>
        </HoverWrapper>
      </ChangeWrapper>
      {/* <NoChangeWrapper>
        <div>{props.children}</div>
        <BottomMobile> || {props.hoverText} </BottomMobile>
      </NoChangeWrapper> */}
    </>
  )
}
export default ChangeText
